/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement("section", null, React.createElement("div", null, React.createElement(_components.h2, null, "Les meilleurs quartiers pour se loger à Florence"), React.createElement("p", null, "Pour se loger, Florence s’articule en cinq quartiers principaux :"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/it/florence/duomo.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Duomo"), " : le berceau de la Renaissance, de la cathédrale au Palazzo Vecchio."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/it/florence/sanlorenzo.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "San Lorenzo"), " et ", React.createElement("a", {
    href: "https://www.booking.com/district/it/florence/sanmarco-santissimaannunziata.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "San Marco"), ", au nord : on apprécie le marché San Lorenzo et le jardin des simples."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/it/florence/santa-maria-novella.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Santa Maria Novella"), ", à l’ouest : quartier de la gare, en contact direct avec le centre historique."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/it/florence/santa-croce.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Santa Croce"), ", à l’est : quartier tranquille non loin de la Galerie des Offices et du Ponte Vecchio."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/it/florence/palazzopitti.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Oltrarno"), ", sur la rive gauche de l’Arno : près du palais Pitti et du jardin de Boboli, quartier connu pour son atmosphère florentine."), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
